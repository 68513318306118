import React from 'react'
import NewsArticlesContent from './NewsArticlesContent'


const Baptism = () => {
  return (
    <NewsArticlesContent
    title="ጥምቀት የድሕን እዩ!"
    content={`1ጴጥ 3.20-22 ግብ 22.16

    “ሰብ ካብ ማይን መንፈስን እንተ ዘይተወልደ ኣብ መንግስቲ ኣምላኽ ኪኣቱ ዝኽእል የልቦን።” ዝብል ጸሑፍ ኣሎ። ዮሃ 3.5። ኣብዚ ዘመን እምነት እምበር ጥምቀት ኣየድሕንን እዩ ዚብሉ ኣለዉ። እንታይ ኣሚኖም ከም ዝድሕኑ ግን ኣየስተውዓሉን። ጥምቀት የድሕን እዩ ኢሎም እንተ ዝኣምኑ ነይሮም፡ እቲ ጥምቀት ብስም መድሓኒና የሱስ ስለ ዝፍጸም ከድሕኖም ምኽኣለ ነይሩ።
    
    እንታይ ኣሚኖም ከም ዝድሕኑ ኣየስተውዓሉን ክብሃል ከሎ፡ የሱስ ኣሚንና ይብሉ ይዀኑ። የሱስ ግና “ሰብ ካብ ማይን መንፈስን እንተ ዘይተወልደ ኣብ መንግስተይ ኣይኣቱን እዩ” ኢሉ ኣዚዙ እዩ። ጐይታ የሱስ ኣብ ምድሪ ኣብ ዝነበረሉ ጊዜ ምስኡ ዝነበሩ ደቀ መዛምርቱ (ሃዋሪያት) እኳ ብጥምቀት ከም ዝደሓኑ ክምስክሩ ከለዉ፡ “ንሱ ብምሕረቱን ብምሕጻብ ሓድሽ ልደትን ብምሕዳስ መንፈስ ቅዱስን እዩ ዘድሓነና” ኢሎም። ቲቶ 3.5።
    
    ሃናንያ ንጳውሎስ “ሕጂኸ ንምንታይ እትድንጒ? ተንሲእካ ተጠመቕ ስም ጐይታ ጸዊዕካ ካብ ሓጢኣትካ ተሓጸብ ኢሉ ንምድሓኑ ኪጥመቕ ኣቀላጢፍዎ፡ ዘይተጠመቐ ክድሕን ስለ ዘይክእል። ግብ 22.16።
    
    ኣብ ዘመን ኖህ ዝነበሩ ሰባት ብሰንኪ ዘይምእዛዞም ክጠፍኡ ከለዉ፡ ንቃሉ እተኣዘዙ ሸሞንተ (8) ነፍሳት ግና ብምኽንያት ማይ ናብ መርከብ ኣትዮም /ተጠሚቖም ከም ዝደሓኑ ተጻሒፉ ኣሎ። 1ጴጥ 3.19-22። እስራኤላውያንውን ካብ ፈርኦንን ሰራዊቱን (ካብ ሞት) ክድሕኑ ኲሉ ኢድ ዝኸኣሉ ብማይ ከም ዝዀነ፡ እቲ ማይ ድማ ናይ ጥምቀት ምሳሌ ከም ዝነበረ ተጻሒፉ ኣሎ። ዘጸ 14፡ 1ቈረ 10.1-3።
    
    ብርግጽ ጥምቀት የድሕን እዩ፡ ግናኸ ንዘይኣመነሉ ከድሕን ስለ ዘይክእል ዝኣመነን እተጠምቐን ይድሕነሉ። እንተ ዘይኣመነሉ ግና ከድሕኖ ኣይክእልን እዩ እሞ ኣሚኑ ብዘይምጥማቑ የዀኑኖ እዩ። ማር 16.16። ምጥማቕ ማለት ብስሙ ኣሚንካ ምስ ክርስቶስ ምቕባርን፡ ንክርስቶስ ለቢስካ ብሓድሽ ህይወት ምትንሳእ ማለት እዩ። ሮሜ 6.3-5።
    
    ብስም የሱስ ምጥማቕ የንጽህ ይቕድስ የጽድቕውን እዩ። 1ቈረ 6.11። መጽሓፍ ቅዱስ “ተነስሑ ነፍሲ ወከፍኩም ከኣ ብስም የሱስ ክርስቶስ ንሕድገት ሓጢኣት ይጠመቕ ህያብ መንፈስ ቅዱስውን ክትቅበሉ ኢኹም ይብል። ግብ 2.38።
    
    ብዙሓት ሰባት ጥምቀት ጥቕሚ ዘለዎ ስለ ዘይመስሎም እንተ ዘይተጠመቕናውን ክንድሕን ንኽእል ኢና እናበሉ ህይወቶም ንፍርዲ ኣዳልዮም ኣለዉ። ጥምቀት ጽድቂ ንምፍጻም ጥራይ እዩ ዝጠቅም ኢሎም ብጥምቀት ዮሃንስ ተጠሚቖም ዝነበሩ ንሕድገት ሓጢኣቶም ኣድላዪ ከም ዝዀነ ኣሚኖም ዳግም ብስም የሱስ ክርስቶስ ከም እተጠምቁ ተጻሒፉ ኣሎ። ግብ 19.1-61 ግብ 10.43-48።
    
    ከምቲ የሱስ ርእሱ ክሳዕ ሞት ኣብ መስቀል ኣሕሊፉ ዝሃበ፡ ንሕናውን ርእስና ብንስሓ ንየሱስ ክንህብ ኣሎና። ንሱ ከም ዝሞተን እተቐብረን ንሕናውን ንዓለምን ርኽሰትን ሞይትና፡ ምስኡ ብጥምቀት ክንቅበር ኣሎና። ከምቲ ንሱ ካብ ምውታት ብዓወት ንዘልኣለም ህያው ኰይኑ ዝተንስአ፡ ንሕናውን ብጥምቀት ካብ እተቐበርናዮ ንክርስቶስ ለቢስና ብሓድሽ ህይወት ንዘለኣለም ክንትንስእ ኣሎና።
    
    ግናኸ ጥምቀት ብዘይ እምነት፡ እምነት ብዘይ ጥምቀት ከድሕን ኣይክእልን እዩ። ካብ እምነት ዘይኰነ ዘበለ ኲሉ ሓጢኣት እዩ። ሮሜ 14. 23፤ 6.3-5። ጥምቀት ብስም የሱስ ክርስቶስ ጥራይ እዩ ክፍጸም ዘለዎ። ንሙሴ ክሓብርዎ ብባሕሪ ከም እተጠመቑ ንሕናውን ንክርስቶስ ክንሓብሮ ብስሙ ክንጥመቕ ስለ ዝግብኣና እዩ። 1ቈረ 10.1-3 ሕድገት ሓጢኣት ንረኽበሉ ስም፡ የሱስ ዝብል ስም ጥራይ ስለ ዝዀነ እዩ። ማቴ 1.21፤ ሉቃ 24.47፤ ግብ 4.12፤ ግብ 10.43-48።
    
    ዝዀነ ይኹን መለኰታዊ ስራሕ ብስም የሱስ ጥራይ ክንገብሮ ካብ መለኰት ስለ እተኣዘዝና እዩ። ቈሎ 3.17።
    
    ንምንታይ ድኣ እቲ ምስ የሱስ ተሰቒሉ ዝነበረ ከታሪ ብዘይ ጥምቀት ድሒኑ? ዚብሉ ብዙሓት እዮም። እቲ ከታሪ ዝድሓነ ብናይ ሓዲሽ ኪዳን ጥምቀት (ማለት ካብ ጐኒ የሱስ ዝፈሰሰ ደምን ማይን ንሕድገት ሓጢኣት ምጥማቕ) ቅድሚ ምጅማሩ ብዝነበረ ናይ ንስሓ (ምንጻህ) ስርዓት እዩ። ጐይታ የሱስ “ሓጢኣትኪ ተሓዲጒልኪ” “ሓጢኣትካ ተሓዲጒልካ” እናበለ ካብ ሓጢኣቶም ዝፈትሖም ዝነበረ ቅድሚ ሞቱ እዩ፡ ድሕሪ ሞቱን ትንሳኤኡን ግና ሰብ ካብ ማይን መንፈስን ክውለድ፡ ብዝሓገጐ እምበር ብዝነበረ ናይ ምንጻህ ስርዓት ምድሓን ኣይተኻእለን። ስለዚ እዩ ከኣ ብዙሓት ደቀ መዛሙርቲ ዮሃንስ ዝነበሩ እንደገና ብስም የሱስ ክርስቶስ እተጠመቑ። ግብ 19.1-6።
    
    መርሖታት ምድሓን ንሃዋርያት ዘረከቦም ካብ መበል ሓምሳ መዓልቲ ጀሚሩ ስለ ዝዀነ፡ ብመሰረት ሃዋርያት ዝሰበኽዎ ቃላት መርሖታት ምድሓን ማለት፡ ብምእማን የሱስ፡ ብንስሓ፡ ብስም የሱስ ክርስቶስ ንሕድገት ሓጢኣት ብምጥማቕን፡ መንፈስ ቅዱስ ብምምላእን፡ ዘይድሓኑ ኲሎም፡ ናብ መንግስቲ ኣምላኽ ኪኣትዉ ከም ዘይክእሉ ቃሉ ብግልጺ ይምስክር። ገላ 1.8-12፤ 1ዮሃ 4.6፤ ሉቃ 10.6፤ ማቴ 16.15-20፤ ይሁ 3-4፤ 17-25፤ ዮሃ 3.5፤ ቲቶ 3.5።
    
    ድምጺ እግዚኣብሄር ኣቦ፡ ንእግዚኣብሄር ወዲ ካብ ሰማይ ንዘስምዖ ዚጋጮ ናይ ፍልስፍና ትምህርቲ ድማ ካብ ዶክተር እሸቱ ኣባተ መጽሓፍ ንርአ።
    
    ምድሓን ዝርከብ ንቓል መለኰት ብምእዛዝ እዩ እሞ፡ ተነሲሑ ንሕድገት ሓጢኣቱ ዘይተጠምቐ ክድሕን ኣይክእልን እዩ። ቃሉ ዝገብርዎ እምበር ጐይታይ ጐይታይ በሃልቲ ኲሎም ኣይድሕኑን እዮም። ማቴ 7.21-231 ሉቃ 7.30፤ ሉቃ 11.28።
    `
    }
    author="Eritrean Apostolic Church"
    date=""
/>
  )
}

export default Baptism
