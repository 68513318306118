import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Navbar from './components/Navbar.js'
import Footer from './components/Footer.js'
import InfoTab from './components/InfoTab.js'
import Home from './pages/Home'
import About from './pages/About'
import Articles from './pages/Articles'
import Books from './pages/Books.js'
import Videos from './pages/Videos'
import Contact from './pages/Contact'
import Locations from './pages/Locations'
import './App.css';
import OneGodOneBody  from './pages/articles/OneGodOneBody'
import Baptism  from './pages/articles/Baptism'
import Resources from './pages/Resources.js'
import GaTracker from './components/GaTracker.js'

function App() {
  return (
    <div className="container">
      <Router>      
      <InfoTab />
      <Navbar />
      <GaTracker />
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/about-us' element={<About/>} />
          <Route path='/books' element={<Books/>} />
          <Route path='/resources' element={<Resources/>} />
          <Route path='/resources/articles' element={<Articles/>} />
          <Route path='/resources/videos' element={<Videos/>} />
          <Route path='/contact-us' element={<Contact/>} />
          <Route path='/locations' element={<Locations/>} />
          <Route path="/resources/articles/one-god-one-body" element={<OneGodOneBody/>} />
          <Route path="/resources/articles/baptism" element={<Baptism/>} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
