import React from 'react';
import ResourcesComp from '../components/ResourcesComp';
import BannerContent from '../components/BannerContent'


const Resources = () => {

  return (
    <section className="page-section">
      <BannerContent title="Our Resources" />

      <div className="resource-container">
          <ResourcesComp />
      </div>

    </section>
  );
};

export default Resources;
