import React from 'react'
import BannerContent from '../components/BannerContent';
import ContactForm from '../components/ContactForm'
const Contact = () => {
  return (
    <section className="page-section">
      <BannerContent
        title="Contacts"
      />
      <ContactForm />
      
    </section>
  )
}

export default Contact
