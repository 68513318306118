import React from 'react';
import { Link } from 'react-router-dom';
import './ResourcesComp.css';

const videoBooksList = [
  {
    linkSource: "/resources/videos",
    coverImage: "/images/video-player.png",
    title: "Videos"
  },
  {
    linkSource: "/books",
    coverImage: "/images/book-preview.png",
    title: "Books"
  }
];

const pdfList = [
  {
    sourceLink: "/resources/hade-amlaK-hade-akal.pdf",
    coverImage: "/images/3-hade-amlak.jpg",
    title: "ሓደ አምላኽ ሓደ ኣካል ጥራይ"
  },
  {
    sourceLink: "/resources/ktdhndo-tdeli.pdf",
    coverImage: "/images/5-ktdhndo-tdeli.png",
    title: "ክትድሕንዶ ትደሊ ኢኻ?"
  },
  {
    sourceLink: "/resources/ab-weld-menfes-kdus.pdf",
    coverImage: "/images/1-ab-weld-menfes-kdus.png",
    title: "ኣቦ ወዲ መንፈስ ቅዱስ ሓደ ኣምላኽ"
  },
  {
    sourceLink: "/resources/mdhan-btmhrti-hawaryat.pdf",
    coverImage: "/images/6-mdhan-btmhrti-hawaryat.png",
    title: "ምድሓን ብትምህርቲ ሃዋርያት"
  },
  {
    sourceLink: "/resources/tmqet-yedhn-eyu.pdf",
    coverImage: "/images/8-tmqet-yedhn-eyu.png",
    title: "ጥምቀት የድሕን እዩ"
  },
  {
    sourceLink: "/resources/nay-men-nismae.pdf",
    coverImage: "/images/7-nay-men-nismae.png",
    title: "ናይ መን ንስማዕ?"
  }
];

const ResourcesComp = () => {
  const pdfItems = pdfList.map(({ sourceLink, coverImage, title }, index) => {
    return (
      <div key={index} className="resource-item">

        <div className="download-link">
          <h1>{title}</h1>
          <img src={coverImage} alt={title} />
          <a href={sourceLink} download>
            <span className='btn-apo-eri'>Download</span>
          </a>
        </div>
      </div>

    );
  });

  const videoBooksItems = videoBooksList.map(({ linkSource, coverImage, title }, index) => {
    return (
      <div key={index} className="resource-item">
        <div className="download-link">
          <Link to={linkSource}>
            <img src={coverImage} alt={title} />
            <h1>{title}</h1>
          </Link>
        </div>
      </div>
    );
  });

  return (
    <section className='page-section'>
      <div className='resources-container'>
        <h2>Tigrinya Doctrine tracts</h2>
        <div className="item-container">{pdfItems}</div>
        <h2>Videos and books</h2>
        <div className="item-container">{videoBooksItems}</div>

      </div>
    </section>
  );
};

export default ResourcesComp;
