import React from 'react'

const BannerContent = ({title}) => {
  return (
    <div>
       <div className='page-header-content'>
        <img src='/images/banner-image.png' alt='Banner content'/>
        <div className='logo-verse'>
          <h1>{title}</h1>
        </div>
      </div>
    </div>
  )
}

export default BannerContent
