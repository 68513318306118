// Footer.js
import React, { useState } from 'react';
import './Footer.css';
// import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [submissionMessage, setSubmissionMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    try {
      const response = await fetch(`https://app-dy5exxufeq-uc.a.run.app/stay-updated`, { 
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: email })        
      });

      if (!response.ok) {
        throw new Error('Failed to update user');
      }
      // document.querySelector('.tb_emailInput').value = ''; 
      setSubmissionMessage('Email received, thank you! We will be in touch with you for special events/conferences informations.')
      setEmail('')
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-column">
          <h3>Apostolic Church Of Eritrea</h3>
          <p>Hear, O Israel: The LORD our God is one LORD. Deuteronomy 6:4</p>
        </div>
        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/resources">Resources</a></li>
            <li><a href="/contact-us">Contact us</a></li>
            <li><a href="/locations">Our Address</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Contact Us</h3>
          <p>Email: contact@apostoliceritrea.org</p>
        </div>
        <div className="footer-column">
          <h3>Reach out to us</h3>
          <p>Stay updated with our latest anouncements.</p>
          {submissionMessage && <p className='alertMsg'>{submissionMessage}</p>}

          <form onSubmit={handleSubmit}>
            <input
              className='tb_emailInput'
              type="email"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={handleChange}
              required
            />
            <button type="submit">submit</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="social-media">
          <a href="/about-us">Our Belief</a>
          <a href="/locations">Visit Us</a>
        </div>
        <p>© 2023 Apostolic Eritrea. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
