import React, { useState, useEffect } from 'react'
import ContactForm from '../components/ContactForm'
import {Link} from 'react-router-dom'
import '../App.css';

const Home = () => {
  const [localTime, setLocalTime] = useState('');
  const [mothersLocalTime, setMothersLocalTime] = useState('');

  useEffect(() => {
    // Service hours start at 3 PM EST
    const serviceStartTimeEST = new Date('2023-12-04T15:00:00-05:00');
    const mothersStartTimeEST = new Date('2023-12-04T18:00:00-05:00');

    // Convert to the user's local time
    const serviceStartTimeLocal = new Date(serviceStartTimeEST.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));
    const mothersStartTimeLocal = new Date(mothersStartTimeEST.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));

    // Format the local time
    const formattedTime = serviceStartTimeLocal.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
    const mothersFormattedTime = mothersStartTimeLocal.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });

    setLocalTime(formattedTime);
    setMothersLocalTime(mothersFormattedTime);
  }, []);

  return (

    <section className='page-section'>
      <div className='logo-home-container'>
        <video src='/videos/logo-vid-bible.mp4' autoPlay loop muted />
        <div className='logo-verse'>
          <h1>ምሕረቱ ኣይተወድኤን እዩ እሞ፡ ጥራስ ዘይጠፋእናስ ጸጋ እግዚኣብሄር እዩ። ኣብ ጸጽባሕ ሓድሽ እዩ፡ እሙን ምዃንካ ዓብዪ እዩ።</h1>
          <h2>ድጒዓ  3:22-23</h2>
        </div>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 160"><path fill="#004AAD" fillOpacity="0.8" d="M0,0L60,5.3C120,11,240,21,360,42.7C480,64,600,96,720,96C840,96,960,64,1080,58.7C1200,53,1320,75,1380,85.3L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>

      <div className='page-header'>
        <h1>Eritrean Apostolic Church Fellowship</h1>
        <h1>ማሕበር ሓዋርያዊት ቤት ክርስትያን ኤርትራ</h1>
      </div>

      <div className='vision-container grid-vision'>
        <div className="vision-section">
          <img className='vision-img' src='./images/vision.png' alt="Gospel's Reach" />
          <div className="vision-text-container">
            <h2 className="vision-title vision-vision">Our Vision</h2>
            <p className="vision-text">We envision a world transformed by the pervasive influence of the gospel Internationally. Our commitment lies in actively spreading the teachings of the Apostolic faith, fostering its acceptance and celebration in diverse cultures and communities worldwide.</p>
          </div>
        </div>

        <div className="vision-section">
          <img className='vision-img' src='./images/growth.png' alt="Fellowship and Growth" />

          <div className="vision-text-container">
            <h2 className="vision-title vision-growth">Fellowship and Growth</h2>
            <p className="vision-text">At the heart of our vision is a dedicated pursuit of growth and service within the fellowship of disciples. We aspire to create an environment that nurtures personal development and empowers individuals to serve with compassion, purpose, and unity.</p>
          </div>
        </div>

        <div className="vision-section">
          <img className='vision-img' src='./images/unity.png' alt="Unity and collaboration" />

          <div className="vision-text-container">
            <h2 className="vision-title vision-unity">Unity and collaboration</h2>
            <p className="vision-text">We are fervently devoted to working harmoniously alongside Apostolic ministers and believers in various nations. Our aim is to build bridges, fostering unity and collaboration, as together, we strive to advance the principles and values inherent in our shared Apostolic mission.</p>
          </div>
        </div>

        <div className="vision-section">
          <img className='vision-img' src='./images/faith-journey.png' alt="Journey of Faith" />

          <div className="vision-text-container">
            <h2 className="vision-title vision-faith">Journey of Faith</h2>
            <p className="vision-text">Through our collective efforts and unwavering dedication, we endeavor to embody and extend these aspirations, inviting all to join us on this transformative journey of faith, service, and unity. </p>
          </div>
        </div>
      </div>

      <div className="gradiend-box">
        <div className="grid-container">
          <div className="service-details">
            <div className="image-service-hours">
              <img src='./images/online-bible-study.jpg' alt="Online Bible Study 1"/>
            </div>

            <div className="title">Our Online Zoom Services Hours</div>
            <div className="hours">
              <i className="icon fas fa-clock"></i>
              <span>Thursday - Saturday: 3:00 PM - 5:00PM EST</span>
            </div>
            <div className="hours">
              <i className="icon fas fa-clock"></i>
              <span>Your Local Time: {localTime}</span>
            </div>
            <div className="location">
              <i className="icon fas fa-map-marker-alt"></i>
              <a className="link" href="https://us02web.zoom.us/j/6317450103?pwd=RFFKT3k4SWhOalBoQmV3UXllMTJFdz09"><span> Meeting ID: 631 745 0103 Passcode: OneGod</span>
              </a>
            </div>
          </div>

          <div className="service-details">

            <div className="image-service-hours">
              <img src="./images/mothers-prayer.jpg" alt="Every Monday Online Zoom Mothers Prayer" />
              <a className="link arrow-link" href="https://us02web.zoom.us/j/6317450103?pwd=RFFKT3k4SWhOalBoQmV3UXllMTJFdz09"><span></span>  </a>

            </div>

            <div className="title">Join the Mothers Prayer Group</div>
            <div className="hours">
              <i className="icon fas fa-clock"></i>
              <span>Every Mondays: 6:00 PM - 8:00 PM EST</span>
            </div>
            <div className="hours">
              <i className="icon fas fa-clock"></i>
              <span>Your Local Time: {mothersLocalTime}</span>
            </div>
            <div className="location">
              <i className="icon fas fa-map-marker-alt">
              </i>
              <a className="link" href="https://us02web.zoom.us/j/6317450103?pwd=RFFKT3k4SWhOalBoQmV3UXllMTJFdz09"><span> Meeting ID: 631 745 0103 Passcode: OneGod</span>  </a>
            </div>
          </div>

          <div className="service-details">
            <div className="image-service-details">
              <img src="/images/one-God.jpg" alt="One God" />
              <Link to="/resources/articles/one-god-one-body/" className="plus-link top">+</Link>
            </div>
            <div className="title">One God | ሓደ ኣምላኽ ሓደ ኣካል ጥራይ</div>
            <div className="text">እቲ ካብ ኣዳም ጀሚሩ ክሳዕ ዘመን ሓዲሽ ኪዳን ዝነበረ ናይ መጽሓፍ ቅዱስ ሃይማኖት ግን፡ ከምዚ ኢሉ ይእውጅ። “ንዂላትናዶ ሓደ ኣቦ ኣይኰነን ዘሎና? ሓደ ኣምላኽዶ ኣይኰነን ዝፈጠረና?...” ሚል 2.10”... </div>
            <Link to="/resources/articles/one-god-one-body/"><button className='btn-apo-eri top'>Read more...</button> </Link>


          </div>

          <div className="service-details">
            <div className="image-service-details">
              <img src="./images/Baptism.jpg" alt="Baptism" />
              <Link to="./resources/articles/baptism/" className="plus-link top">+</Link>
            </div>
            <div className="title">Baptism | ጥምቀት የድሕን እዩ</div>
            <div className="text">ኣብ ዘመን ኖህ ዝነበሩ ሰባት ብሰንኪ ዘይምእዛዞም ክጠፍኡ ከለዉ፡ ንቃሉ እተኣዘዙ ሸሞንተ (8) ነፍሳት ግና ብምኽንያት ማይ ናብ መርከብ ኣትዮም /ተጠሚቖም ከም ዝደሓኑ ተጻሒፉ ኣሎ። 1ጴጥ 3.19-22።</div>
            <Link to="./resources/articles/baptism/"><button className='btn-apo-eri top'>Read more...</button> </Link>
          </div>
        </div>
      </div>

      <div className='page-header contact-us'>
        <h1>Contact Us</h1>
      </div>
      <ContactForm />
    </section>
  )
}

export default Home
