import React from 'react'
import BannerContent from '../components/BannerContent';
// import VideoPlayerComp from '../components/VideoPlayerComp';



const Videos = () => {
  // const videoList = [
  //   {
  //     title: "ዝስሓተ ናይ ሃይማኖት ትምህርቲ ዘለዎም ናይ እምነት ክፍልታት ብምንታይ እዮም ዝፍለጡ What are the characteristics of a denomination that has a false religious teaching?",
  //     videoSrc: "https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2Fkidane.zewde%2Fvideos%2F3983822541723502%2F&show_text=false&width=560&t=0"
  //   },
  //   {
  //     title: "ጐይታና የሱስ ክርስቶስ እግዚአብሔር አብ ድዩ?…",
  //     videoSrc: "https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2Fkidane.zewde%2Fvideos%2F3983822541723502%2F&show_text=false&width=560&t=0"
  //   },
  //   {
  //     title: "የሱስ ክንስዕባ ዝኣዘዘና ሓንቲ እምነት፡ናይታ ሓቂ ዓንድን መሰረትን ዝዀነት ማሕበር ህያው ኣምላኽ ወይ ሓንቲ ቤተክርስትያን እንተኾይና ንመንታይ ሃይማኖታት በዚሑ እቲኣ የሱስ ዝቅበላ ሓንቲ እምነት ኣብ መጽሃፍ ቁዱስ ዘላኸ ብከመይ ክንፈልጣ ንኽእል?",
  //     videoSrc: "https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fkidane.zewde%2Fvideos%2F4139569362726083%2F&show_text=false&width=357&t=0"
  //   }

    
    
  // ]
  return (
    <section className="page-section">
      <BannerContent
        title="Videos"
      />
    
    </section>
  )
}

export default Videos
