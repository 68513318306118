import React from 'react';
import NewsArticlesContent from './articles/NewsArticlesContent';

const About = () => {
  return (
    <section className="page-section">

      <NewsArticlesContent
        title="Our Core Beliefs"
        content={`The Eritrean Apostolic Christian Church represents a fellowship of devout individuals, united by our love for Jesus Christ and adherence to His teachings, which serve as a reflection of divine love, grace, and veracity. Our foremost goal is to honor the Lord Jesus Christ by reflecting His infinite love for us. We hold the conviction that Jesus Christ is the divine incarnate, who manifested in the flesh to save humanity from the shackles of sin, the inevitability of death, and the despair of Hell (2 Timothy 3:16). The Holy Scriptures are esteemed as the flawless Word of God, devoid of error. Our congregation fosters a profound affinity for Biblical study and endeavors to embody the precepts of God in our daily sojourn with Him.

        The doctrinal foundation of the Eritrean Apostolic Christian Church is deeply entrenched in the Holy Bible. We affirm that the Bible is the divinely inspired, unfailing Word of God bestowed upon mankind (2 Peter 1:20-21). Both the New Testament and the Old Testament constitute the bedrock established by the apostles and prophets (Acts 2:1-4).

        We profess the existence of a singular deity, God Almighty, the architect of all entities in the celestial and terrestrial realms, encompassing all that is perceptible and imperceptible, be it thrones, dominions, principalities, or powers; all creation is the work of His hands, crafted for His purpose (Genesis 1:1, John 1:1, Revelation 1:8, Revelation 22:18-19).

        In the wake of a declaration of faith and spiritual transformation, adherents enter into a solemn commitment to uphold fidelity to God. The sacrament of baptism (Acts 2:38, Galatians 3:27-28), conducted through full immersion, is administered in the revered name of the Lord Jesus Christ (Acts 8:16, Acts 19፡1-6, Acts 19:5, Acts 22:16, Romans 6:3, Cor. 6-11).

        It is incumbent upon believers to embrace the Holy Spirit, as assured by the Lord Jesus Christ. This pivotal moment is recognized and commemorated through a sanctified prayer of dedication, succeeded by the imposition of hands by a church elder, subsequent to baptism. As integral members of the Body of Christ, the faithful are privy to continual spiritual maturation and edification within the ecclesiastical community (Luke 24:49, Acts 2:1-4, Acts 1:8, 1 Corinthians 12:13, 1 Corinthians 6:19, Romans 8:26).         
        `}
        author="Eritrean Apostolic Church"
        date=""
      />
    </section>
  );
};

export default About;
