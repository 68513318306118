import React from 'react'
import './Navbar.css'

const InfoTab = () => {
    return (
        <div className='info-tab'>
            <div className='email-us'>
            <i className="fa fa-envelope"></i>
                <h3>contact@apostoliceritrea.org</h3>
            </div>
        </div>
    )
}

export default InfoTab
