import React, { useState } from 'react'
import { MenuList } from './MenuList'
import './Navbar.css';
import { NavLink, Link } from 'react-router-dom';

const Navbar = () => {
  const [clicked, setClicked] = useState(false)
  const menuList = MenuList.map(({ url, title }, index) => {
    return (
      <li key={index}>
        <NavLink to={url} activelassname="active">{title}</NavLink>
      </li>
    )
  })

  const menuHandle = () => {
    setClicked(!clicked)
  }

  const closeMenu = () => {
    setClicked(false);
  };

  return (
    <nav>
      <div className='logo'>
        {/* Apostolic <font>Eritrea</font> */}
        <Link
          to="/">
          <img src='./images/logo-apostolic-eritrea.png' alt='logo' />
        </Link>
      </div>

      <ul onClick={closeMenu} className={clicked ? "menu-list" : "menu-list close"}>
        {menuList}
      </ul>

      <div className='btn-visit-us'>
        <Link to="/locations/" className='visit-us'> Visit Us</Link>
      </div>

      <div className='menu-icon' onClick={menuHandle}>
        <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
      </div>

    </nav>
  )
}

export default Navbar
