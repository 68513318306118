import React from 'react';
import BannerContent from '../components/BannerContent';
import BookPreview from '../components/BookPreview';

const Books = () => {
  const bookList = [
    {
      linkSource: "/resources/amilakna-men-eyu.pdf",
      coverImage: "/images/bookcover.jpg",
      title: "አምላኽናን ፈጣሪናን መን ኢዩ?",
      description: "እቲ ክነምለኾ ዝግብኣና ኣምላኽናን ፈጣሪናን መን እዩ? ሰለስተ ኣካል ዘለዎ ስላሴ ወይስ ሓደ ኣካል ዘለዎ ኣምላኽ? ብዝብል ኣርእስቲ ዝተዳለወት ብመሰረት መጽሓፍ ቅዱስ ካብ ቃሉ ነቲ ሓቀኛ ኣምላኽን ፈጣርን ማንነት ትገልጽ ኮይና ተገዲሱን ኣስተውዒሉን ንዘንብባ ዝኾነ ይኹን ትግርኛ ተዛራቢ ክርድኦ ብዝኽእል ብሩህን ቀሊልን ኣገላልጻ ዝተዳለወት ስለ ዝኾነት ነቲ ሓቀኛ እግዚኣብሄር ኣምላኽናን ፈጣሪናን ንምፍላጥ ንዝደልዩ እትሕግዝ እያ።",
      author: "Author: Kidane Zewde, 2021"
    },
    {
      linkSource: "/resources/mezmur.pdf",
      coverImage: "/images/mezmur.jpg",
      title: "መዛሙር Vol 1 ሕታም",
      description: "ሓዋርያዊት ቤተ ክርስትያን ኤርትራ መዛሙር ትግርኛ",
      author: "Author: Eritrean Apostolic Church, 1996"
    } 
  ];

  return (
    <section className="page-section">
      <BannerContent title="Books" />
      {bookList.map((book, index) => (
        <BookPreview key={index} book={book} />
      ))}
    </section>
  );
};

export default Books;
