import React from 'react'
import './NewsArticles.css'
import BannerContent from '../../components/BannerContent';

const NewsArticlesContent = ({ title, content, author, date }) => {
  return (
    <>
      <section className="page-section">
        <BannerContent
          title={title}
        />
      </section>
      <article className="news-article">
        <div className="news-article-inner">
          <h2 className="news-title">{title}</h2>
          <p className="news-content">{content}</p>
          <div className="news-footer">
            <span className="news-author">{author}</span>
            <time className="news-date">{date}</time>
          </div>
        </div>
      </article>
    </>
  )
}

export default NewsArticlesContent
