import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const GaTracker = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize("G-M647G3449N");
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
    return null;
}
export default GaTracker



