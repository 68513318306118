export const MenuList = [
    {
        title: "Home",
        url: "/"
    },
    {
        title: "About",
        url: "/about-us/"
    },
    {
        title: "Book",
        url: "/books/"
    },
    {
        title: "Resource",
        url: "/resources/"
    },
    {
        title: "Contact",
        url: "/contact-us/"
    }
]