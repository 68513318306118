import React from 'react'
import BannerContent from '../components/BannerContent';

const Articles = () => {
  return (
    <section className="page-section">
      <BannerContent
        title="Articles"
      />
    </section>
  )
}

export default Articles
