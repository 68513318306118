import React from 'react';
import './BookPreview.css';

const BookPreview = ({ book }) => {
  
  return (
    <div className='book-container'>
      <div className="book-preview">
        <div className="book-cover">
          <img src={book.coverImage} alt={book.title} />
        </div>
        <div className="book-info">
          <h2 className="book-title">{book.title}</h2>
          <p className="book-description">{book.description}</p>
          <p className="book-author">{book.author}</p>
          <a href={book.linkSource} className="btn-open-book" target="_blank" rel="noreferrer">Open book</a>
        </div>
      </div>
    </div>
  );
};

export default BookPreview;
