import React from 'react'
import './Pages.css';
import './ContactCard.css';
import BannerContent from '../components/BannerContent';


const contactList = [
  {
    name: 'Pastor Kidane Zewde',
    location: 'Housten, USA',
    address: '5850 Gulfton St, Housten, TX, 77081',
    phone: '+1 713-834-5113',
    schedule: '3:00 PM - 5:00 PM (Housten, USA)'
  },

  {
    name: 'Minister Noah Efrem',
    location: 'Toronto, Canada',
    address: '1232 Birchmount Rd, Toronto, ON ON M1P 2C3',
    phone: '+1 (647) 771-5527',
    schedule: '3:30 PM - 5:00 PM (Toronto, Canada)'
  },

  {
    name: 'Pastor Efrem Gebremeskel',
    location: 'London, United Kingdom',
    address: "St Philip's Church, Earls Ct Rd London W8 6QH, United Kingdom",
    phone: '+44 7838 870288',
    schedule: '15:30 - 19:00 (London, UK)'
  },

  {
    name: 'Pastor Kibreab Abraha',
    location: 'Sweden',
    phone: '+46 72 032 75 32'
  },

  {
    name: 'Minister Endryas Okbay',
    location: 'Switzerland',
    phone: '+41 78 620 27 17'
  },

  {
    name: 'Pastor Samuel Belay',
    phone: '+249 96 663 1954'
  },

  {
    name: 'Minister Habtom Hagos',
    location: 'Luwanda, Angola',
    phone: '+244 937 287 913 or +244 998 061 584'
  }

];

const Locations = () => {
  return (
    <section className="page-section">
      <BannerContent
         title= "Location"
      />

      <div className='contact-container'>
        {contactList.map((contact, index) => (

          <div key={index} className="contact-card">
            <h2>{contact.name}</h2>
            <p><strong>Location:</strong> {contact.location}</p>
            <p><strong>Address:</strong> {contact.address}</p>
            <p><strong>Phone:</strong> {contact.phone}</p>
            <p><strong>Schedule:</strong> {contact.schedule}</p>
          </div>

        ))}
      </div>
    </section>
  )
}

export default Locations
